import React from 'react'
import { useForm } from 'react-hook-form'
import _get from 'lodash.get'

import { templateCreate as templateCreateApi } from 'api/templates'
import Button from 'components/Button'
import Box from 'components/Box'
import Form from 'components/Form'
import { useOpenModal } from 'components/Modal'
import { useToast } from 'components/Toast'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import TEMPLATE_TYPES from 'constants/templates'
import { isFeatureEnabled } from 'utils/features'
import { GLOOD_PROXY } from 'constants/features'

export default function AddTemplateModal({
  templates,
  account,
  close,
  onCreate,
  defaultType,
}) {
  const openToast = useToast()
  const openModal = useOpenModal()
  const existingTemplates = _get(templates, 'data.templates', [])
  const [saving, setSaving] = React.useState(false)
  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      name: '',
      source: defaultType || existingTemplates.length == 0 ? 'none' : existingTemplates[0].id,
      type: defaultType || existingTemplates.length == 0 ? defaultType : existingTemplates[0].type,
    },
  })
  const formValues = watch()

  const sourceOptions = [{ label: 'None', value: 'none' }].concat(
    existingTemplates
      .filter((temp) => {
        if (defaultType) {
          return temp.type === defaultType
        }
        return true
      })
      .map((t) => ({
        label: t.name,
        value: t.id,
      }))
  )
  const templateTypeOptions = Object.keys(TEMPLATE_TYPES).map((type) => {
    const { label, value } = TEMPLATE_TYPES[type]
    return {
      label,
      value,
    }
  })

  function openUpgradeAccountModal() {
    openModal(
      <UpgradeAccountModal account={account} featureToEnable={GLOOD_PROXY} />
    )
  }

  async function createTemplate(data) {
    setSaving(true)
    try {
      data.source = data.source === 'none' ? undefined : parseInt(data.source)
      if (data.type === TEMPLATE_TYPES.BUNDLES_PAGE.value) {
        if (!isFeatureEnabled(account.data, GLOOD_PROXY)) {
          openUpgradeAccountModal()
          return
        }
      }
      const response = await templateCreateApi(data, {
        shop: account.data.name,
      })

      const newTemplate = _get(response, 'data.template')
      if (newTemplate) {
        openToast({
          type: 'success',
          text: 'Template added successfully',
        })
        close()
        onCreate(newTemplate)
      } else {
        openToast({
          type: 'error',
          text: 'There was an error in creating the template. Please ensure all fields are correct. Contact support for more information',
        })
      }
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in creating the template. Please ensure all fields are correct. Contact support for more information',
      })
    }
    setSaving(false)
  }

  return (
    <Box className="flex flex-col items-stretch">
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit(createTemplate)}
      >
        <Form.Field
          label="Template Name*"
          name="name"
          control="input"
          className="flex-1"
          type="text"
          ref={register({ required: 'Name is required' })}
          spacing="loose"
          helpText="Name of the template."
          formError={errors.name}
        />
        <Form.Field
          label="Source Template*"
          name="source"
          control="select"
          options={sourceOptions}
          ref={register({ required: true })}
          spacing="loose"
          helpText="Choose source template. Liquid, CSS, JS & Settings will be copied from this into the new template."
          formError={errors.source}
        />
        <Form.Field
          label="Template Type*"
          name="type"
          control="select"
          options={templateTypeOptions}
          ref={register({ required: true })}
          disabled={Boolean(formValues.source !== 'none' || defaultType)}
          readOnly={Boolean(formValues.source !== 'none' || defaultType)}
          {...(formValues.source !== 'none'
            ? {
                value: existingTemplates.find((template) => {
                  return template.id === parseInt(formValues.source)
                })?.type,
              }
            : {})}
          spacing="loose"
          helpText="Choose template type."
          formError={errors.type}
        />
        <div className="flex flex-row justify-end">
          <Button loading={saving}>Save</Button>
        </div>
      </form>
    </Box>
  )
}
