import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'

import Box from 'components/Box'
import Button from 'components/Button'
import InfoBox from 'components/InfoBox'
import Form from 'components/Form'
import ProductDiscounts from '../ProductDiscounts'
import { openChat } from 'utils/external'
import { canAddProductDiscounts, isShopifyPlus } from 'utils/account'
import { useApi } from 'hooks/api'
import * as Layouts from 'constants/layouts'
import * as Widgets from 'constants/widgets'
import * as Pages from 'constants/pages'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import { useOpenModal } from 'components/Modal'
import { isFeatureEnabled } from 'utils/features'
import { PRODUCT_DISCOUNTS } from 'constants/features'
import Icons from 'components/Icons'
import CodeEditor from 'components/CodeEditor'

export default function Discounts({
  account,
  widget,
  onSave,
  onDelete,
  className,
  mode,
  onChange,
  headingClassName,
}) {
  const { name: shopName, shop, plan } = account.data
  const layoutSupported =
    widget.type === Widgets.BOUGHT_TOGETHER.value
      ? widget.layout !== 'amazon_bought_together'
      : true
  const shopifyPlusStore = isShopifyPlus(account.data)
  const productDiscountsSupported = shopifyPlusStore && layoutSupported
  const prodDiscountsEnabled = isFeatureEnabled(account.data, PRODUCT_DISCOUNTS)
  const openModal = useOpenModal()
  function openUpgradeAccount() {
    openModal(
      <UpgradeAccountModal
        account={account}
        featureToEnable={PRODUCT_DISCOUNTS}
      />
    )
  }

  async function onWidgetSave(newData) {
    if (widget?.excludedProducts) {
      widget.excludedProducts = widget.excludedProducts.map((prod) => prod.id)
    }
    if (widget?.disabledProducts) {
      widget.disabledProducts = widget.disabledProducts.map((prod) => prod.id)
    }
    if (widget?.whitelistedProducts) {
      widget.whitelistedProducts = widget.whitelistedProducts.map(
        (prod) => prod.id
      )
    }

    await onSave({
      ...widget,
      discountConfig: { ...widget.discountConfig, ...newData },
    })
  }

  let child
  if (shop.accessScopes.indexOf('write_price_rules') === -1) {
    child = (
      <UpdatePermissionsCallout
        shop={shopName}
        className={mode !== 'builder' ? '' : 'mx-6'}
      />
    )
  } else if (widget.pageType !== Pages.PRODUCT.value) {
    child = (
      <BundleDiscountUnsupported
        mode="page"
        className={mode !== 'builder' ? '' : 'mx-6'}
      />
    )
  } else if (widget.layout !== Layouts.AMAZON_BOUGHT_TOGETHER.value) {
    child = (
      <BundleDiscountUnsupported
        mode="layout"
        className={mode !== 'builder' ? '' : 'mx-6'}
      />
    )
  } else {
    child = (
      <DiscountForm
        widget={widget}
        onWidgetSave={onWidgetSave}
        mode={mode}
        onChange={onChange}
        headingClassName={headingClassName}
        plan={plan}
        upgradeAccountModal={openUpgradeAccount}
      />
    )
  }

  return (
    <div className={classNames('flex flex-col', className)}>
      {child}
      {
        <div className="flex flex-col items-end w-full">
          <div
            className={classNames('w-full', {
              'opacity-50 pointer-events-none': !productDiscountsSupported,
            })}
          >
            <ProductDiscounts
              account={account}
              widget={widget}
              onSave={onSave}
              onUpgrade={!prodDiscountsEnabled && openUpgradeAccount}
              mode={mode}
              onChange={onChange}
              headingClassName={headingClassName}
            />
          </div>
          {!productDiscountsSupported && (
            <span className="text-xs text-red-500">
              Product discounts are only available on layouts other than 'Amazon
              Like Bought Together' for Shopify Plus customers.
            </span>
          )}
        </div>
      }
    </div>
  )
}

function BundleDiscountUnsupported({ mode, className }) {
  return (
    <InfoBox
      type="warning"
      title={`${
        mode === 'layout' ? 'Layout' : 'Page'
      } does not support Bundle Discounts`}
      action={{
        content: 'Get In Touch',
        onAction: () => openChat(),
        external: false,
      }}
      className={className}
    >
      <p>
        Bundle discounts is only available for{' '}
        <strong>"Amazon Like Bought Together" layout</strong> on the{' '}
        <strong>Product Page</strong>.{' '}
        {mode === 'layout' &&
          'Please update the widget layout from "Design & Location" tab to enable discounts.'}
      </p>
    </InfoBox>
  )
}

function UpdatePermissionsCallout({ shop, className }) {
  const permission = useApi(`shopify/auth/update_permissions_link/`, {
    shop: shop,
  })

  function onGrantPermission(data) {
    const { updatePermissionsLink: link } = permission.data
    window.top.location.href = link
  }

  return (
    <InfoBox
      type="warning"
      title="Grant us permission to create bundle discounts!"
      action={{
        content: 'Grant Permission',
        onAction: onGrantPermission,
      }}
      className={className}
    >
      <div>
        <p>
          Once we have your permission, you can provide bundle discounts on the
          Frequently Bought Together widget.
        </p>
      </div>
    </InfoBox>
  )
}

function DiscountForm({
  widget,
  onWidgetSave,
  mode,
  onChange,
  headingClassName,
  plan,
  upgradeAccountModal,
  className,
}) {
  const isFeatureEnabled = Boolean(
    plan.featuresEnabled.find((feature) => feature === 'discounts')
  )
  const [discountConfig, setDiscountConfig] = useState(
    JSON.stringify(widget.discountConfig, null, 2)
  )
  const [error, setError] = useState(null)

  useEffect(() => {
    if (onChange && typeof onChange === 'function') {
      try {
        const json = JSON.parse(discountConfig)
        onChange(json)
      } catch (e) {
        setError('Invalid JSON')
      }
    }
  }, [discountConfig])

  const saveHandler = async () => {
    try {
      const json = JSON.parse(discountConfig)
      await onWidgetSave(json)
    } catch (e) {
      setError('Invalid JSON')
    }
  }

  return (
    <Box className="mb-5">
      <div
        className={classNames(
          'mb-5 text-sm font-semibold text-gray-800 uppercase',
          headingClassName
        )}
      >
        Bundle Discount Settings
      </div>
      {
        error && <div>
          <span className="text-red-500">{error}</span>
        </div>
      }
      <div
        className="flex flex-col items-stretch flex-1 w-full"
      >
        <CodeEditor
          mode="json"
          value={JSON.stringify(widget.discountConfig, null, 2)}
          onChange={setDiscountConfig}
        />
        <div className='w-full flex items-end justify-end'>
          <Button onClick={saveHandler} className="mt-3">
            Save
          </Button>
        </div>
      </div>
      <div
        className={classNames(
          'mb-5 text-sm font-semibold text-gray-800 uppercase',
          headingClassName
        )}
      >
        Sample JSON structure for discount
      </div>
      <code>
        <pre className="whitespace-pre-wrap">
          {JSON.stringify(
            {
              enabled: true,
              value: "20",
              widget_message: {
                primary_locale: "Get {amount} discount",
                is: "Icelandic text",
              },
              type: "AMOUNT",
              version: 2,
              apply_discount_only_to_rec: true,
              message: {
                ar: "Arabic text",
                primary_locale: "Bundle discount for {product_title}",
              },
            },
            null,
            2
          )}
        </pre>
      </code>
    </Box>
  )
}
